"use client";
import React, { useState } from "react";

export default function BottomNavItem({ item, isActive, onClick }: any) {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`navigation-item flex cursor-pointer flex-col items-center ${
        isActive ? "font-semibold" : "text-slate-400"
      }`}
      onClick={onClick}
      onPointerEnter={() => setHovered(true)}
      onPointerLeave={() => setHovered(false)}
    >
      <div className="relative">
        <item.icon data-hovered={hovered} className="h-6 w-6" />
        {isActive && (
          <div className="absolute -inset-1 -z-10 rounded-full bg-lime-50 blur-sm"></div>
        )}
      </div>
      <span className="title mt-1 text-xs">{item.title}</span>
    </div>
  );
}
