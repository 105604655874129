"use client";
import "./index.scss";
import CarouselItem from "../post-carousel/carousel-item";
import { useEffect, useState, useRef } from "react";
import Link from "next/link";

export default function PostCarouselAuto({ posts }: any) {
  const [startItemActive, setStartItemActive] = useState(0);
  const [endItemActive, setEndItemActive] = useState(2); // Track the active index
  const [itemWidth, setItemWidth] = useState<any>();
  const [translateX, setTranslateX] = useState(0);
  const [itemsToShow, setItemsToShow] = useState(6);
  const carouselWrapperRef = useRef(null);

  useEffect(() => {
    const calculateItemsToShow = () => {
      if (!carouselWrapperRef.current) {
        return;
      }
      const carouselWidth = (carouselWrapperRef.current as any)?.clientWidth;
      let itemsToShow = 3;
      if (carouselWidth > 780 && carouselWidth < 1024) {
        itemsToShow = 5;
      } else if (carouselWidth >= 1024) {
        itemsToShow = 6;
      }

      setItemsToShow(itemsToShow);
      const screenWidth = carouselWidth - 48;
      const itemWidth = Math.floor(screenWidth / itemsToShow);

      setItemWidth(itemWidth);
      // Reset end item;
      const endItemActive = 0 + itemsToShow;
      setEndItemActive(endItemActive);
    };

    calculateItemsToShow(); // Initial calculation
    window.addEventListener("resize", calculateItemsToShow); // Update on resize

    return () => {
      window.removeEventListener("resize", calculateItemsToShow); // Cleanup listener
    };
  }, []);

  const movingCarousel = (index: number) => {
    const pageIndex = Math.floor(index / itemsToShow);
    // Calculate the translateX value based on the active index
    let translateX = 0;
    if (pageIndex > 0) {
      translateX = -pageIndex * (itemWidth * itemsToShow);
    }
    const startItemActive = pageIndex * itemsToShow;
    const endItemActive = startItemActive + itemsToShow;
    setStartItemActive(startItemActive);
    setEndItemActive(endItemActive);
    setTranslateX(translateX);
  };

  // Handle next and previous button clicks
  const handleNext = () => {
    const newStartIndex = startItemActive + itemsToShow;
    if (newStartIndex < posts.length) {
      movingCarousel(newStartIndex);
    }
  };

  const handlePrevious = () => {
    const newStartIndex = startItemActive - itemsToShow;
    if (newStartIndex >= 0) {
      movingCarousel(newStartIndex);
    }
  };

  return (
    <div className="relative flex w-full items-center overflow-hidden px-5 pb-8 pt-8 [mask-image:linear-gradient(to_right,transparent,black_20%,black_80%,transparent)] md:px-0">
      <div className="flex w-max min-w-max items-center gap-5 transition-[filter,opacity] duration-300 [--scroll:-100%] group-hover:opacity-30 group-hover:blur-sm motion-safe:animate-infinite-scroll motion-safe:[animation-duration:36s]">
        {(posts || []).map((item: any, index: number) => {
          return (
            <Link
              key={index}
              href={`/posts/${item.slug}`}
              style={{ width: `${itemWidth ?? 150}px` }}
            >
              <CarouselItem imageUrl={item.image} title={item.title} />
            </Link>
          );
        })}
      </div>
      <div className="flex w-max min-w-max items-center gap-5 transition-[filter,opacity] duration-300 [--scroll:-100%] group-hover:opacity-30 group-hover:blur-sm motion-safe:animate-infinite-scroll motion-safe:[animation-duration:36s]">
        {(posts || []).map((item: any, index: number) => {
          return (
            <Link
              key={index}
              href={`/posts/${item.slug}`}
              style={{ width: `${itemWidth ?? 150}px` }}
            >
              <CarouselItem imageUrl={item.image} title={item.title} />
            </Link>
          );
        })}
      </div>
    </div>
  );
}
