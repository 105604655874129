import { useTranslation } from "@/app/i18n/client";
import { cn } from "@/lib/utils";
import debounce from "lodash/debounce";
import React, { useEffect, useRef, useState } from "react";

interface IProps {
  countries: any;
  cities: any;
  wards: any;
  onChange?: (value: string) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  formClass?: string;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  size?: string;
  type?: string;
}

const InputAddress: React.FC<IProps> = ({
  countries,
  cities,
  wards,
  onChange,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation("common");
  const [address, setAddress] = useState({
    country: countries[0]?.name,
    countryCode: countries[0]?.code,
    city: "",
    cityCode: "",
    ward: "",
    wardCode: "",
    address: "",
  });

  const [dropdown, setDropdown] = useState(""); // Tracks which dropdown is open
  const [search, setSearch] = useState(""); // Tracks search input in dropdowns

  /*
   * Update address component value
   * @field: field to update in address object
   * @option: selected option from dropdown, or input value
   */
  const handleChange = (field: any, option: any) => {
    const updatedAddress = {
      ...address,
      [field]: typeof option === "string" ? option : option.name,
      [`${field}Code`]: option.code,
    };

    // Reset dependent fields if parent value changes
    if (field === "country") {
      updatedAddress.city = "";
      updatedAddress.ward = "";
      updatedAddress.cityCode = "";
      updatedAddress.wardCode = "";
    } else if (field === "city") {
      updatedAddress.ward = "";
      updatedAddress.wardCode = "";
    }

    setAddress(updatedAddress);
    setDropdown(""); // Close dropdown on selection
    setSearch(""); // Clear search input
    const addressString = updatedAddress.address
      ? `${updatedAddress.address}, `
      : "";
    const wardString = updatedAddress.ward ? `${updatedAddress.ward}, ` : "";
    const cityString = updatedAddress.city ? `${updatedAddress.city}, ` : "";
    const countryString = updatedAddress.country
      ? `${updatedAddress.country}, `
      : "";
    const combinedAddress = `${addressString}${wardString}${cityString}${countryString}`;
    onChange?.(combinedAddress);
  };

  /*
   * Debounce address string update to prevent multiple updates
   */
  const updateAddressString = debounce((value: string) => {
    handleChange("address", value);
  }, 300);

  const filteredOptions = (options: any, field: any) => {
    return options.filter(
      (option: any) =>
        field === "country" ||
        (field === "city" && option.fullCode.startsWith(address.countryCode)) ||
        (field === "ward" &&
          option.fullCode.startsWith(
            `${address.countryCode}_${address.cityCode}`,
          )),
    );
  };

  const onChangeDropdownType = (type: string) => {
    setDropdown(type);
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);
  };

  return (
    <div className="mt-1 space-y-2">
      {/* Address Display */}
      <div className="flex h-[40px] items-center space-x-[2px] rounded-lg border border-gray-300 px-1">
        {/* Country */}
        <span
          onClick={() => onChangeDropdownType("country")}
          className={cn(
            "cursor-pointer px-1",
            address.country ? "text-gray-700" : "text-gray-500",
          )}
        >
          {address.country ? address.country : t("order.selectCountry")}
        </span>

        {/* City */}
        {address.country && (
          <>
            <span>\</span>

            <span
              onClick={() => onChangeDropdownType("city")}
              className={cn(
                "cursor-pointer px-1",
                address.city ? "text-gray-700" : "text-gray-500",
              )}
            >
              {address.city ? address.city : t("order.selectCity")}
            </span>
          </>
        )}

        {/* Ward */}
        {address.city && (
          <>
            <span>\</span>
            <span
              onClick={() => onChangeDropdownType("ward")}
              className={cn(
                "cursor-pointer px-1",
                address.ward ? "text-gray-700" : "text-gray-500",
              )}
            >
              {address.ward ? address.ward : t("order.selectWard")}
            </span>
          </>
        )}

        {/* Address */}
        {address.ward && (
          <>
            <span>\</span>
            <input
              type="text"
              placeholder={t("order.enterAddress")}
              defaultValue={address.address}
              onChange={(e) => updateAddressString(e.target.value)}
              className="flex-1 rounded px-2 outline-none"
            />
          </>
        )}
      </div>

      {/* Dropdown */}
      {dropdown && (
        <AddressDropdown
          dropdown={dropdown}
          options={filteredOptions(
            dropdown === "country"
              ? countries
              : dropdown === "city"
                ? cities
                : wards,
            dropdown,
          )}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

interface DropdownProps {
  dropdown: string;
  options: any[];
  handleChange: (dropdown: string, option: any) => void;
}

const AddressDropdown: React.FC<DropdownProps> = ({
  dropdown,
  options,
  handleChange,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<"above" | "below">("below");
  const [displayOptions, setDisplayOption] = useState(options);

  const filteredOptions = (search: any) => {
    setDisplayOption(
      options.filter((option: any) =>
        option.searchName.toLowerCase().includes(search.toLowerCase()),
      ),
    );
  };

  useEffect(() => {
    const handlePosition = () => {
      if (dropdownRef.current) {
        const rect = dropdownRef.current.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (rect.bottom > windowHeight) {
          setPosition("above");
        } else {
          setPosition("below");
        }
      }
    };

    handlePosition();
    window.addEventListener("resize", handlePosition);
    return () => {
      window.removeEventListener("resize", handlePosition);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`absolute z-10 w-full rounded-md bg-white p-4 shadow-md ${
        position === "above" ? "bottom-full mb-2" : "top-full mt-2"
      }`}
    >
      <input
        type="text"
        placeholder={`Search ${dropdown}`}
        onChange={(e) => filteredOptions(e.target.value)}
        className="mb-2 w-full rounded border border-gray-300 px-2 py-1"
      />
      <div className="max-h-40 overflow-y-auto">
        {displayOptions.map((option: any) => (
          <div
            key={option.name}
            onClick={() => handleChange(dropdown, option)}
            className="cursor-pointer p-2 text-slate-500 transition-all duration-100 hover:text-slate-600"
          >
            {option.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InputAddress;
