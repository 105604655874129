"use client";

import React from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import { useAuthStore, useOrderStore } from "@/src/stores/rootStoreProvider";
import { useTranslation } from "@/app/i18n/client";
import { Home1Icon } from "@/ui/animate-icons/home-1";
import { ShoppingBag } from "@/ui/animate-icons/shopping-bag";
import { History } from "@/ui/animate-icons/history";
import { Profile } from "@/ui/animate-icons/profile";
import BottomNavItem from "./bottom-nav-item";

export default function BottomNavigationBar({ user }: any) {
  const pathname = usePathname();
  const authStore = useAuthStore();
  const orderStore = useOrderStore();
  const { t } = useTranslation("common");

  const navItems = [
    { icon: Home1Icon, title: t("header.home"), path: "/" },
    { icon: ShoppingBag, title: t("header.cart"), path: "/cart" },
    { icon: History, title: t("header.orders"), path: "/orders" },
    { icon: Profile, title: t("header.profile"), path: "/profile" },
  ];

  const openProfile = () => {
    if (user) {
      authStore.setShowProfile(true);
    } else {
      authStore.setShowSignIn(true);
    }
  };

  const openCart = () => {
    orderStore.showCartBottomSheet();
  };

  return (
    <>
      <section
        className="navigation-bar sticky bottom-0 left-0 right-0 mb-0 mt-4 rounded-t-xl bg-white"
        style={{ boxShadow: "0 0 9px rgba(147, 96, 3, 0.2)" }}
      >
        <div className="items flex justify-around py-2">
          {navItems.map((item, index) => {
            const isActive = pathname === item.path;
            if (item.path.toLowerCase() === "/profile") {
              return (
                <BottomNavItem
                  key={index}
                  item={item}
                  isActive={isActive}
                  onClick={openProfile}
                />
              );
            }
            if (item.path.toLowerCase() === "/cart") {
              return (
                <BottomNavItem
                  key={index}
                  item={item}
                  isActive={isActive}
                  onClick={openCart}
                />
              );
            }
            return (
              <Link href={item.path} key={index}>
                <BottomNavItem item={item} isActive={isActive} />
              </Link>
            );
          })}
        </div>
      </section>
    </>
  );
}
